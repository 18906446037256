import React, {useEffect, useState} from 'react';
// import logo from './logo.svg';
import './App.css';
import axios from "axios";

export interface MenuModel {
    "id": string,
    "title": string,
    "href": string,
    "icon_class_name": string,
    "link_title": string,
    "sort": number
}


function App() {
    const [title, setTitle] = useState<string>();
    const [date, setDate] = useState<string>();
    const [menu, setMenu] = useState<MenuModel[]>();
    const getSoulInfo = async () => {
        let url = "https://www.guojq.com/soul_api/v1/data/info";
        return await axios.get(url).then(res => {
            let data = res.data;
            setTitle(data.title)
        });
    };
    const getMenuInfo = async () => {
        let url = "https://www.guojq.com/soul_api/v1/data/menu";
        return await axios.get(url).then(res => {
            let data = res.data;
            debugger
            setMenu(data)
        });
    };

    useEffect(() => {
        setTitle('小小狗贼，可笑可笑')
        const timer = setInterval(function () {
            getSoulInfo()
            console.log("test")
        }, 10000)
        return () => {
            clearInterval(timer)
        }

    }, []);

    useEffect(() => {
        let year = new Date().getFullYear();
        setDate(year.toString())
        getMenuInfo()
    }, [])

    const openUrl = (item: MenuModel) => {
        console.log(item)
        window.open(item.href);
    }

    return (
        <div className="App">
            {/*<img src={logo} className="App-logo" alt="logo"/>*/}
            <div className="wrapper">
                <div className="bg"></div>
                <div className="overlay"></div>
                <div className="main">
                    <header className="header">
                        <div className="title"> {title}</div>
                        <p>&nbsp;&bull;&nbsp; 只有烂掉的需求，没有烂掉的技术 &nbsp;&bull;&nbsp;</p>
                        <ul>
                            {
                                menu?.map((item: MenuModel, index: number) => {
                                    return <>
                                        <li key={item.id} data-index={index}>
                                            <i className={item.icon_class_name}
                                               title={item.title}
                                               onClick={(event) => {
                                                   openUrl(item)
                                               }}>
                                                <span>{item.title}</span>
                                            </i>
                                        </li>
                                    </>
                                })
                            }
                        </ul>
                    </header>
                    <footer className="footer">
                    <span className="copyright">
                        &nbsp;&copy; 2014-{date} &nbsp;&nbsp;
                        <a href="http://www.guojq.com/">家秋</a>丨&nbsp;
                        <a href="http://beian.miit.gov.cn/" rel="nofollow">鲁ICP备14034576号-9</a>
                    </span>
                    </footer>
                </div>
            </div>


        </div>

    );
}

export default App;
